import { gql } from '@apollo/client';
import { Account } from 'interfaces/Account';
import { AreaOfInterest } from 'interfaces/AreaOfInterest';
import { Category } from 'interfaces/Category';
import { Reporter } from 'interfaces/Reporter';
import { Status } from 'interfaces/Status';
import { Team } from 'interfaces/Team';
import { WorkOrder } from 'interfaces/WorkOrder';

export interface GetWorkOrderFiltersData {
  organization: {
    workorders: {
      aggs: Pick<
        WorkOrderAggregations,
        'categories' | 'statuses' | 'areasOfInterest' | 'teams' | 'assignees' | 'tags'
      >;
    };
  };
}

export interface GetWorkOrderFiltersVariables {
  organizationId: string;
  createdFrom: number;
  createdTo: number;
}
export interface GetAssigneeFiltersData {
  organization: {
    workorders: {
      aggs: Pick<WorkOrderAggregations, 'assignees'>;
    };
  };
}

export interface GetAssigneeFiltersVariables {
  organizationId: string;
}

export interface GetWorkOrdersData {
  organization: {
    workorders: {
      nodes: WorkOrderDisplay[];
    };
  };
}

export interface GetWorkOrdersVariables {
  organizationId: string;
  residents?: string;
}

export interface CategorySummaryAggregation {
  category: Pick<Category, 'id' | 'name'>;
  averageLabor: number;
  averageDuration: number;
  workOrderCount: number;
  closedWorkOrderCount: number;
}

export interface WorkSummaryAggregations {
  averageLabor: number;
  averageDuration: number;
  workOrderCount: number;
}

export interface GetWorkSummaryReportData {
  organization: {
    workorders: {
      aggs: Pick<WorkOrderAggregations, 'heatmap'>;
      nodes: WorkOrderDisplay[];
    };
    categorySummary: {
      nodes: CategorySummaryAggregation[];
      aggs: WorkSummaryAggregations;
    };
  };
}

export interface GetWorkSummaryReportVariables {
  organizationId: string;
  createdFrom: number;
  createdTo: number;
  categories?: string;
  statuses?: string;
  areasOfInterest?: string;
  street?: string;
  teams?: string;
  assignees?: string;
  tags?: string;
}

export type Resident = Pick<Reporter, 'id' | 'name' | 'email' | 'phone'>;

export interface WorkOrderDisplay
  extends Pick<
    WorkOrder,
    'id' | 'createdAt' | 'closedAt' | 'titleDisplay' | 'location' | 'status' | 'tags' | 'assets'
  > {
  category: Pick<Category, 'id' | 'name'>;
  assignee: Pick<Account, 'id' | 'name'>;
  team: Pick<Team, 'id' | 'name'>;
  reporter: Resident;
}

export interface WorkOrderAggregations {
  categories?: {
    nodes: {
      category: Pick<Category, 'id' | 'name'>;
      count: number;
    }[];
  };
  statuses?: {
    nodes: {
      status: Status;
      count: number;
    }[];
  };
  areasOfInterest?: {
    nodes: {
      areaOfInterest: Pick<AreaOfInterest, 'id' | 'name'>;
      count: number;
    }[];
  };
  teams?: {
    nodes: {
      team: Pick<Team, 'id' | 'name'>;
      count: number;
    }[];
  };
  assignees?: {
    nodes: {
      assignee: Pick<Account, 'id' | 'name'>;
      count: number;
    }[];
  };
  heatmap?: {
    features: string;
    viewport: [number, number, number, number];
  };
  tags?: {
    nodes: {
      tag: string;
      count: number;
    }[];
  };
}

const residentFragment = {
  resident: gql`
    fragment Resident on Reporter {
      id
      name
      email
      phone
      secondaryPhone
    }
  `,
};

const workorderDisplayFragment = {
  workorderDisplay: gql`
    fragment WorkOrderDisplay on WorkOrder {
      id
      createdAt
      closedAt
      title
      workorderNumber
      titleDisplay @client
      location {
        address
        latitude
        longitude
      }
      status
      category {
        id
        name
      }
      assignee {
        id
        name
      }
      team {
        id
        name
      }
      reporter {
        ...Resident
      }
      assets {
        edges {
          node {
            id
            name
            assetType
          }
        }
      }
    }
    ${residentFragment.resident}
  `,
};

export const GET_WORK_ORDER_FILTERS = gql`
  query GetWorkOrderFilters($organizationId: ID!, $createdFrom: Float, $createdTo: Float) {
    __typename
    organization(id: $organizationId) {
      id
      workorders(params: { createdFrom: $createdFrom, createdTo: $createdTo }) {
        aggs {
          tags {
            nodes {
              tag
              count
            }
          }
          categories {
            nodes {
              category {
                id
                name
              }
              count
            }
          }
          statuses {
            nodes {
              status
              count
            }
          }
          areasOfInterest {
            nodes {
              areaOfInterest {
                id
                name
              }
              count
            }
          }
          teams {
            nodes {
              team {
                id
                name
              }
              count
            }
          }
          assignees {
            nodes {
              assignee {
                id
                name
              }
              count
            }
          }
        }
      }
    }
  }
`;
export const GET_ASSIGNEE_FILTERS = gql`
  query GetWorkOrderFilters($organizationId: ID!) {
    __typename
    organization(id: $organizationId) {
      id
      workorders(params: { statuses: "SUBMITTED,STARTED,REOPENED,ONHOLD" }) {
        aggs {
          assignees {
            nodes {
              assignee {
                id
                name
              }
              count
            }
          }
        }
      }
    }
  }
`;

export const GET_WORK_ORDERS_BY_RESIDENT = gql`
  query GetWorkOrders($organizationId: ID!, $residents: String) {
    __typename
    organization(id: $organizationId) {
      id
      workorders(params: { reporters: $residents }) {
        nodes {
          ...WorkOrderDisplay
        }
      }
    }
  }
  ${workorderDisplayFragment.workorderDisplay}
`;

export const GET_WORK_SUMMARY_REPORT = gql`
  query GetWorkSummaryReport(
    $organizationId: ID!
    $createdFrom: Float!
    $createdTo: Float!
    $categories: String
    $statuses: String
    $areasOfInterest: String
    $street: String
    $teams: String
    $assignees: String
    $tags: String
  ) {
    __typename
    organization(id: $organizationId) {
      id
      workorders(
        params: {
          createdFrom: $createdFrom
          createdTo: $createdTo
          categories: $categories
          statuses: $statuses
          areasOfInterest: $areasOfInterest
          street: $street
          teams: $teams
          assignees: $assignees
          tags: $tags
        }
      ) {
        nodes {
          ...WorkOrderDisplay
        }
        aggs {
          heatmap {
            viewport
            features
          }
        }
      }
      categorySummary(
        params: {
          createdFrom: $createdFrom
          createdTo: $createdTo
          categories: $categories
          statuses: $statuses
          areasOfInterest: $areasOfInterest
          street: $street
          teams: $teams
          assignees: $assignees
          tags: $tags
        }
      ) {
        nodes {
          category {
            id
            name
          }
          averageDuration
          averageLabor
          workOrderCount
          closedWorkOrderCount
        }
        aggs {
          averageLabor
          averageDuration
          workOrderCount
        }
      }
    }
  }
  ${workorderDisplayFragment.workorderDisplay}
`;

export interface GetActiveWorkordersData {
  workorders: {
    nodes: WorkOrder[];
    edges: {
      node: WorkOrder;
    }[];
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
}
export interface GetActiveWorkordersVariables {
  organizationId: string;
}

export const GET_ACTIVE_WORK_ORDERS = gql`
  query GetActiveWorkOrders($organizationId: ID!) {
    workorders(query: { organizationId: $organizationId, active: true }) {
      nodes {
        ...WorkOrderDisplay
      }
      edges {
        node {
          ...WorkOrderDisplay
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${workorderDisplayFragment.workorderDisplay}
`;
