import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import { Content, ContentColumns, TDocumentDefinitions } from 'pdfmake/interfaces';

import {
  AssigneeWorkOrderProps,
  createContent as assigneeWorkOrdersList,
} from '../AssigneeWorkOrders/createContent';

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

export interface CreateDocumentConfig {
  assigneeWorkOrders: AssigneeWorkOrderProps[];
}

const createHeader = (): ContentColumns => {
  return {
    columns: [{ text: 'Assignments Report', style: 'header' }],
  };
};

export const createDocument = ({
  assigneeWorkOrders,
}: CreateDocumentConfig): TDocumentDefinitions => {
  // Top margin is header height + header vert margins
  const pageMargins: [number, number, number, number] = [40, 30 + 30 + 16, 40, 60];

  const images: Record<string, string> = {};

  const content: any[] = [];

  const section: Content = {
    stack: assigneeWorkOrdersList(assigneeWorkOrders),
  };

  content.push(section);

  return {
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    pageMargins,
    header: { margin: [40, 30, 40, 0], ...createHeader() },
    footer: (currentPage: number) => ({
      margin: [40, 20, 40, 0],
      columns: [
        {
          alignment: 'left',
          style: 'timestampFooter',
          text: `Report created: ${moment().format('MM/DD/YYYY, h:mm A')}`,
        },
        {
          alignment: 'right',
          text: currentPage.toString(),
        },
      ],
    }),
    images,
    content,
    defaultStyle: {
      fontSize: 10,
      lineHeight: 1.2,
    },
    // Styles are almost identical to Work Order Details PDF. Maybe factor out to common style.
    styles: {
      // Page header (H1)
      header: {
        fontSize: 16,
        bold: true,
      },
      // Page subheader
      subheader: {
        fontSize: 11,
        color: '#555555',
      },
      // Header for each section (H2)
      sectionHeader: {
        fontSize: 12,
        color: '#333333',
        bold: true,
      },
      // Table header row cells (TH)
      tableHeader: {
        fontSize: 9,
        color: '#999999',
        alignment: 'center',
      },
      // Timestamp footer
      timestampFooter: {
        color: '#555555',
      },
      // Vertical spacing is always on top to separate from content above
      verticalSpacingSm: {
        margin: [0, 5, 0, 0],
      },
      verticalSpacingMd: {
        margin: [0, 10, 0, 0],
      },
      verticalSpacingLg: {
        margin: [0, 20, 0, 0],
      },
    },
  };
};
