import { useQuery } from '@apollo/client';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import InfiniteList from 'components/InfiniteList';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import WorkOrderReporterItem from 'components/WorkOrderItem/WorkOrderReporterItem';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { FilterType, WorkOrderFilterProps, useWorkOrderFilters } from 'containers/WorkOrderFilters';
import { useHistoryPersist } from 'hooks/useHistoryPersist';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { WorkOrder } from 'interfaces/WorkOrder';
import {
  downloadOutline as downloadIcon,
  personOutline as personIcon,
  alertCircleOutline as warningIcon,
} from 'ionicons/icons';
import {
  GET_ACTIVE_WORK_ORDERS,
  GET_ASSIGNEE_FILTERS,
  GetActiveWorkordersData,
  GetActiveWorkordersVariables,
  GetAssigneeFiltersData,
  GetAssigneeFiltersVariables,
} from 'pages/Reports/graphql';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import PdfService from 'services/PdfService';

import { createDocument } from './components/PdfGenerator/createDocument';

export interface PathParams {
  createdFrom?: string;
  createdTo?: string;
  categories?: string;
  statuses?: string;
  areasOfInterest?: string;
  street?: string;
  teams?: string;
  assignees?: string;
  tags?: string;
}

const toPathParam = (ids: string[] | string) => {
  if (!Array.isArray(ids)) return ids;

  return ids.reduce<string | undefined>((acc, id) => (acc ? `${acc},${id}` : id), undefined);
};

const initActiveAssignee = (pathParams: PathParams): string => {
  return pathParams.assignees ? pathParams.assignees : 'all';
};

const initFilterGroup = (availableFilters: Map<string, boolean>, activeFilters?: string) => {
  if (!activeFilters) return availableFilters;

  const filterState = new Map(availableFilters);

  const activeFilterIds = activeFilters.split(',');

  activeFilterIds.forEach((id) => filterState.set(id, true));

  return filterState;
};

const useStyles = createUseStyles({
  toolbar: {
    '& > ion-item::part(native)': {
      background: 'var(--ion-toolbar-background)',
    },
  },
  assignee: {
    marginTop: 'calc(var(--ion-margin, 16px) * 0.5)',
    marginBottom: 'calc(var(--ion-margin, 16px) * 0.5)',
    marginLeft: 'calc(var(--ion-margin, 16px) * 0.5)',
    maxWidth: '240px',
    borderRadius: '4px',
  },
  select: {
    maxWidth: 'fit-content',
    '&::part(icon)': {
      marginLeft: 'var(--ion-margin, 16px)',
    },
    '&.select-disabled': {
      // Disabled workspace select looks like enabled but without the icon and hover styles
      opacity: 1,
      '&::part(icon)': {
        display: 'none',
      },
    },
  },
  popover: {
    '& ion-list': {
      padding: 0,
    },
    '--offset-x': '-16px',
  },
});

const AssignmentsReport: React.FC = () => {
  const pageRef = useRef<HTMLElement>(null);
  const classes = useStyles();

  const [pathParams, setPathParams] = useHistoryPersist<PathParams>();

  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const [prevOrgId, setPrevOrgId] = useState(workspace.organizationId);

  const [activeAssignee, setActiveAssignee] = useState(initActiveAssignee(pathParams));

  const [isDownloading, setIsDownloading] = useState(false);

  const { data: filtersData } = useQuery<GetAssigneeFiltersData, GetAssigneeFiltersVariables>(
    GET_ASSIGNEE_FILTERS,
    {
      displayName: 'WorkOrderAssignmentsReport',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        organizationId: workspace.organizationId,
      },
      onError: () => addMessage('Unable to load report.', 'danger'),
    }
  );

  const {
    data: activeWorkorders,
    refetch: fetchActiveWorkorders,
    loading: workorderLoading,
  } = useQuery<GetActiveWorkordersData, GetActiveWorkordersVariables>(GET_ACTIVE_WORK_ORDERS, {
    displayName: 'WorkOrderAssignmentsReport',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: workspace.organizationId,
    },
    onError: () => addMessage('Unable to load report.', 'danger'),
  });

  // Map GraphQL connections to lists of nodes
  const { assignees: assigneeAgg } = useMemo(() => {
    const aggs = filtersData?.organization.workorders.aggs || {};

    return (Object.keys(aggs) as (keyof typeof aggs)[]).reduce<WorkOrderFilterProps>(
      (acc, key) => ({ ...acc, [key]: aggs[key]!.nodes }),
      { categories: [], statuses: [], areasOfInterest: [], teams: [], assignees: [], tags: [] }
    );
  }, [filtersData]);

  // backend returns both null assignees and 'NONE' assignee ids, remove former from filters
  const assignees = useMemo(() => {
    return assigneeAgg.filter((assignee) => assignee.assignee?.id);
  }, [assigneeAgg]);

  // Initialize filters reducer state from server
  const { filters, isChecked, update } = useWorkOrderFilters({
    assignees: assignees.reduce<any[]>(
      (acc, { assignee }) => (assignee ? [...acc, assignee] : acc),
      []
    ),
  });

  const activeWorkordersList = useMemo(() => {
    return activeWorkorders?.workorders.nodes || [];
  }, [activeWorkorders]);

  const workordersByAssignee = useMemo(() => {
    return activeWorkordersList.reduce(
      (acc, workorder) => {
        if (!workorder.assignee?.id) {
          acc.get('NONE')!.workorders.push(workorder);
          return acc;
        }

        const assigneeId = workorder.assignee?.id;
        if (!acc.has(assigneeId)) {
          acc.set(assigneeId, {
            name: workorder.assignee?.name || '',
            workorders: [workorder],
          });
        } else {
          acc.get(assigneeId)!.workorders.push(workorder);
        }

        return acc;
      },
      new Map<string, { name: string; workorders: WorkOrder[] }>([
        ['NONE', { name: 'No Assignee', workorders: [] }],
      ])
    );
  }, [activeWorkordersList]);

  // InfiniteList component expects an array
  const workordersByAssigneeList = useMemo(() => {
    return Array.from(workordersByAssignee.keys(), (assignee) => {
      return {
        id: assignee,
        name: workordersByAssignee.get(assignee)!.name,
        workorders: workordersByAssignee.get(assignee)!.workorders,
      };
    })
      .filter((assignee) => {
        // only show the filtered assignees if filter is active
        if (pathParams.assignees && !pathParams.assignees.includes('all'))
          return Boolean(pathParams.assignees.includes(assignee?.id || 'NONE'));
        else return true;
      })
      .filter((assignee) => assignee.workorders.length > 0);
  }, [workordersByAssignee, pathParams.assignees]);

  const filteredWorkordersCount = useMemo(() => {
    return workordersByAssigneeList.reduce((acc, assignee) => {
      return acc + assignee.workorders.length;
    }, 0);
  }, [workordersByAssigneeList]);

  // Initialize filters reducer state from path params
  useEffect(() => {
    update(FilterType.Assignee, initFilterGroup(filters.assignees, pathParams.assignees));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // reset assignee filter when organization changes
  useEffect(() => {
    if (workspace.organizationId !== prevOrgId) {
      setPrevOrgId(workspace.organizationId);
      setActiveAssignee('all');
    }
  }, [workspace.organizationId, prevOrgId]);

  // Map filters state to list of active filters
  const activeFilters = useMemo<Record<FilterType, string>>(() => {
    return (Object.keys(filters) as FilterType[]).reduce<any>((acc, filter) => {
      const criteria =
        filter === FilterType.Street
          ? filters[FilterType.Street]
          : [...filters[filter].keys()].reduce<string[]>((list, criterion) => {
              return isChecked(filter, criterion) ? [...list, criterion] : list;
            }, []);

      return {
        ...acc,
        [filter]: criteria,
      };
    }, {});
  }, [filters, isChecked]);

  const generatePdf = () => {
    setIsDownloading(true);

    const docDefinition = createDocument({
      assigneeWorkOrders: workordersByAssigneeList,
    });

    PdfService.open({
      docDefinition,
      fileName: 'Assignments Report',
      onComplete: (success, error) => {
        setIsDownloading(false);
        !success && addMessage(error as string, 'danger');
      },
    });
  };

  // Update path params on filters state update
  useEffect(() => {
    setPathParams({
      assignees: toPathParam(activeAssignee),
    });
  }, [activeFilters, activeAssignee, setPathParams]);

  return (
    <IonPage id="assignee-report" ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref="/reports" />
          </IonButtons>

          <IonTitle>Assignments Report</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={() => generatePdf()}>
              {isDownloading ? (
                <IonSpinner color="primary" name="crescent" />
              ) : (
                <IonIcon icon={downloadIcon} slot="icon-only" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {assignees.length > 1 && (
          <IonToolbar className={classes.toolbar}>
            <IonItem className={classes.assignee} lines="none">
              <IonSelect
                interface="popover"
                placeholder="Select Assignee"
                interfaceOptions={{ cssClass: classes.popover, alignment: 'start' }}
                value={activeAssignee}
                onIonChange={(e) => setActiveAssignee(e.detail.value)}
                className={classes.select}
              >
                <IonSelectOption value="all" key="all">
                  All Assignees
                </IonSelectOption>
                {Array.from(assignees).map(
                  (assignee) =>
                    assignee.assignee?.id &&
                    assignee.assignee?.name && (
                      <IonSelectOption key={assignee.assignee?.id} value={assignee.assignee?.id}>
                        {assignee.assignee?.name}
                      </IonSelectOption>
                    )
                )}
              </IonSelect>
            </IonItem>
          </IonToolbar>
        )}
      </IonHeader>

      <IonContent
        style={{
          '--padding-bottom': 'calc(var(--ion-margin, 16px) + var(--ion-safe-area-bottom, 0))',
        }}
      >
        <PullToRefresh onRefresh={fetchActiveWorkorders} />

        <IonLoading isOpen={workorderLoading} />

        {filteredWorkordersCount > 0 && (
          <React.Fragment>
            <ListSeparator />
            {filteredWorkordersCount > 999 && (
              <IonItem lines="full" color="secondary">
                <IonIcon icon={warningIcon} slot="start" />
                <IonLabel>
                  <h5 className="ion-text-wrap">
                    Showing most recent 999 of {filteredWorkordersCount} active work orders.
                  </h5>
                  <p className="ion-text-wrap">Filter by Assignee to avoid this limit.</p>
                </IonLabel>
              </IonItem>
            )}
            <AuthorizeRequired required={[AccountAction.ListWorkOrders]}>
              {/* Extra space between map and list. Feels cluttered without it */}
              <div
                style={{
                  height: 4,
                  background: 'var(--ion-item-background, var(--ion-background-color, #fff))',
                }}
              />

              <InfiniteList
                title="Work Orders by Assignee"
                pageRef={pageRef}
                searchKeys={['name']}
                items={workordersByAssigneeList}
                itemCount={workordersByAssigneeList.length}
                itemSize={isPlatform('ios') ? 86 : 90}
                renderListItem={(assignee, index, items) =>
                  assignee.workorders.length > 0 && (
                    <React.Fragment key={assignee.id}>
                      <IonItemDivider mode="md" sticky={true}>
                        <IonIcon icon={personIcon} slot="start" />
                        <IonLabel>
                          <h2>
                            {assignee.name} - {assignee.workorders.length} Work Order
                            {assignee.workorders.length > 1 ? 's' : ''}
                          </h2>
                        </IonLabel>
                      </IonItemDivider>
                      <InfiniteList
                        title={`Work Orders - ${assignee.name}`}
                        pageRef={pageRef}
                        searchKeys={['workorderNumber', 'title']}
                        items={assignee.workorders}
                        itemCount={assignee.workorders.length}
                        itemSize={isPlatform('ios') ? 86 : 90}
                        renderListItem={(workorder) => (
                          <React.Fragment key={workorder.id}>
                            <WorkOrderReporterItem workorder={workorder} key={workorder.id} />
                          </React.Fragment>
                        )}
                      />
                    </React.Fragment>
                  )
                }
              />
            </AuthorizeRequired>
          </React.Fragment>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AssignmentsReport;
