import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

const WorkSummaryReport: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Reports</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ListSeparator />

        <IonList className="ion-no-padding">
          <IonItem lines="full" routerLink={`${path}/create/worksummary`}>
            <IonLabel className="ion-text-wrap">
              <h2>Work Summary Report</h2>
              <p style={{ marginTop: 4 }}>Summarize work performed during a specific date range</p>
            </IonLabel>
          </IonItem>
          <IonItem lines="full" routerLink={`${path}/create/resident`}>
            <IonLabel className="ion-text-wrap">
              <h2>Resident Report</h2>
              <p style={{ marginTop: 4 }}>List work orders submitted by a resident</p>
            </IonLabel>
          </IonItem>
          <IonItem lines="full" routerLink={`${path}/create/assigneesummary`}>
            <IonLabel className="ion-text-wrap">
              <h2>Assignments Report</h2>
              <p style={{ marginTop: 4 }}>
                Generate printable reports of work orders assigned to individual team members
              </p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default WorkSummaryReport;
