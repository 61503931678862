import { parseAddress } from 'hooks/useLocation';
import { useStatus as fetchStatus } from 'hooks/useStatus';
import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';
import { Content } from 'pdfmake/interfaces';
import { prettyPhone } from 'utils/prettyPhone';

export interface AssigneeWorkOrderProps {
  id: string;
  name: string;
  workorders: WorkOrder[];
}

export const createContent = (assignees: AssigneeWorkOrderProps[]): Content[] => {
  return [
    { text: 'Work Orders by Assignee', style: 'sectionHeader' },

    assignees.map((assignee, index): Content[] => [
      {
        text: `${assignee.name} - ${assignee.workorders.length} Work Order${
          assignee.workorders.length > 1 ? 's' : ''
        }`,
        style: 'sectionHeader',
      },
      {
        style: 'verticalSpacingMd',
        fontSize: 8,
        table: {
          headerRows: 1,
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              { text: 'Date', style: 'tableHeader' },
              { text: 'Title', style: 'tableHeader' },
              { text: 'Address', style: 'tableHeader' },
              { text: 'Status', style: 'tableHeader' },
              { text: 'Category', style: 'tableHeader' },
              { text: 'Resident name', style: 'tableHeader' },
              { text: 'Resident phone', style: 'tableHeader' },
              { text: 'Resident email', style: 'tableHeader' },
            ],
            ...assignee.workorders.map((task): Content[] => [
              moment(task.createdAt).format('MM/DD/YYYY'),
              task.title,
              parseAddress(task.location?.address) || '',
              { text: fetchStatus(task.status).label, alignment: 'center' },
              { text: task.category?.name || '', alignment: 'center' },
              { text: task.reporter?.name || '', alignment: 'center' },
              {
                text: `${prettyPhone(task.reporter?.phone || '')}${
                  task.reporter?.phone && task.reporter?.secondaryPhone ? ' ' : ''
                }${prettyPhone(task.reporter?.secondaryPhone || '')}`,
                alignment: 'center',
              },
              { text: task.reporter?.email || '', alignment: 'center' },
            ]),
          ],
        },
        layout: {
          hLineWidth: () => 0.5,
          hLineColor: (i, node) =>
            i === 0 || i === (node.table.headerRows || 1) || i === node.table.body.length
              ? '#000000'
              : '#CCCCCC',
          vLineWidth: (i, node) => (i === 0 || i === node.table.body[0].length ? 0.5 : 0),
          // Text is slightly high in cell if top and bottom match. Maybe from lineHeight.
          // Small offset to compensate and vertically center.
          paddingTop: () => 9,
          paddingBottom: () => 7,
          paddingLeft: () => 10,
          paddingRight: (i) => (i === 0 ? 0 : 10),
        },
        pageBreak: index < assignees.length - 1 ? 'after' : undefined,
      },
    ]),
    // .flat(),
  ];
};
